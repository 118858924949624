import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "requests-and-responses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#requests-and-responses",
        "aria-label": "requests and responses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requests and responses`}</h1>
    <p>{`Processing flows differ by claiming type:`}</p>
    <ul>
      <li parentName="ul">{`Medicare Bulk Bill claims: requests are processed in real-time and responses are typically provided the following business morning. Asynchronous claim handling is required with webhook registration encouraged for processing status updates.`}</li>
      <li parentName="ul">{`Medicare Patient Claims: requests are processed in real-time and partners should expect a synchronous response within 60 seconds.`}</li>
      <li parentName="ul">{`DVA claims: requests are processed in real-time and responses are typically provided within two business days. Asynchronous claim handling is required with webhook registration encouraged for processing status updates.`}</li>
    </ul>
    <p>{`Services Australia may periodically bring down production systems. In these cases, a response code of `}{`"`}{`3004`}{`"`}{` will be returned and you should retry the transaction. For Medicare Bulk Bill and DVA claims, we will attempt an automated retry and only return a failure on exceptional system outages.`}</p>
    <p>{`Referral details may be required to submit a Medicare or DVA claim:`}</p>
    <ul>
      <li parentName="ul">{`Medical Specialists, including Diagnostic Imaging and Pathology, will require a valid referral or non-referral reason code.`}</li>
      <li parentName="ul">{`Allied Health professions will likewise require a valid referral or non-referral reason code. Exceptions may apply to primary Optical and Dentistry services.`}</li>
    </ul>
    <p>{`Key response payloads for Medicare and DVA are identical and the response codes at claim and claim item level will apply equally to Medicare and DVA.`}</p>
    <p>{`For Patient Claims specifically, there is also an option to set claimant details - where someone other than the patient pays for the service and receives the Medicare benefit. Claimant details will be required when the patient is under 15 years old as at date of service.`}</p>
    <p>{`For Bulk Bill and DVA claims, by default, the servicing provider will be set to the payee provider (the recipient for bulk bill payments). However, a different payee provider number can be specified at each transaction or as a default for the provider in the Medipass portal.`}</p>
    <p>{`For all transactions, it`}{`'`}{`s important the patient and/or claimant details match exactly to that listed on a Medicare or DVA card rather than what may be recorded in your system.`}</p>
    <p>{`Other notable constraints:`}</p>
    <ul>
      <li parentName="ul">{`There is no taxable supply flag:`}
        <ul parentName="li">
          <li parentName="ul">{`All Medicare services are GST exempt.`}</li>
          <li parentName="ul">{`DVA services may be taxable but Services Australia do not have a method to identify/flag those items as such. Amounts passed should be gross and include applicable tax.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`There is no quantity, other than field quantity and duration which is for certain time based services. If multiple items are claimed, those should be listed as separate claimableItems.`}</li>
      <li parentName="ul">{`Service descriptions are not supported. However, if there is a need to explain services or exceptions, the serviceText field should be used. Note that claims with this set will likely be manually reviewed by Medicare/DVA.`}</li>
      <li parentName="ul">{`There are limits on the number of claimableItems for claims:`}
        <ul parentName="li">
          <li parentName="ul">{`Medicare Bulk Bill: up to 80 services per claim`}</li>
          <li parentName="ul">{`Medicare Patient Claim: up to 16 services per claim`}</li>
          <li parentName="ul">{`DVA claim: up to 80 services per claim`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Items can be claimed for service dates up to two years prior to date of lodgement.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      